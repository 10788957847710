module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/assets/images/datablocks-logo.svg","name":"datablocks","short_name":"datablocks","start_url":"/","background_color":"#f7f0eb","theme_color":"#1A192B","display":"standalone","lang":"en","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"2021d4192c72a1a17b1a23a92ca60046"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
